import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAccordion.figmaUrl.ios} codeUrl={checklists.componentAccordion.codeUrl.ios} checklists={checklists.componentAccordion.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Accordion is used to toggle between hiding and showing large amount of content`}</p>
    <p>{`Use Legion accordion styles to modify accordion with lots of attributes that make you easier.`}</p>
    <p>{`Default LegionUI, ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAccordion("title") {
  Text("hello")
}
`}</code></pre>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/accordion/accordion-ios-1.gif",
            "width": 250,
            "height": 500,
            "align": "center",
            "resize-mode": "contain"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <p>{`Legion Have 2 Variants of Accordion :`}</p>
    <div {...{
      "className": "item-three-container"
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Expand ON`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
        `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fb19c660d-5b68-46d1-81bb-25aeb5978b99%2FScreen_Shot_2022-12-15_at_2.13.57_PM.png?id=164b70a8-1f74-4c87-a152-105d0cf82ec3&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=1520&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
        `}</div>{`
    `}<pre parentName="div">{`      `}<code parentName="pre">{`LGNAccordion("Title", isInitialyExpanded: true) {Text("Put your expandable content here. It can be anything.")}`}</code>{`
    `}</pre>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Expand OFF`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fad3f409d-0679-4aa8-9748-d7f8b102d470%2FScreen_Shot_2022-12-15_at_2.13.39_PM.png?id=dfd70eef-2151-40f1-b166-8937f8821e16&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=1550&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
    `}</div>{`
    `}<pre parentName="div">{`      `}<code parentName="pre">{`LGNAccordion("Title") {Text("Put your expandable content here. It can be anything.")}`}</code>{`
    `}</pre>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Attribute`}</h2>
    <p>{`Legion Have 6 Attributes for Customize Accordion :`}</p>
    <h3><strong parentName="h3">{` Line `}</strong></h3>
    <div {...{
      "className": "item-three-container"
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Expand ON`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Faffd4cd4-33fe-46b4-99f9-9b491c5e28b1%2FScreen_Shot_2022-12-15_at_2.22.49_PM.png?id=d25c5a42-7f71-42dd-a8fc-69b63544220f&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=380&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
    `}</div>{`
    `}<pre parentName="div">{`      `}<code parentName="pre">{`LGNAccordion("Title",hasBottomLine: true, isInitialyExpanded: true)
        {Text("Put your expandable content here. It can be anything.")}`}</code>{`
    `}</pre>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Expand OFF`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F8a0c4a32-c53d-4612-9f58-979e9a2e25bb%2FScreen_Shot_2022-12-15_at_2.24.05_PM.png?id=4ed04cf6-0513-4a6a-9e4f-dd12717e16cd&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=380&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
    `}</div>{`
    `}<pre parentName="div">{`      `}<code parentName="pre">{`LGNAccordion("Title", hasBottomLine: true) {Text("Put your expandable content here. It can be anything.")}`}</code>{`
    `}</pre>{`
  `}</div>
    </div>
    <h3><strong parentName="h3">{` Icon `}</strong></h3>
    <p>{`This code sample demonstrates how to modify icon :`}</p>
    <div {...{
      "className": "item-three-container"
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Expand ON`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F016fcd5b-dd89-4eaf-9b59-3757d08c20ff%2FScreen_Shot_2022-12-15_at_2.32.01_PM.png?id=10b45a5e-125e-48af-b2de-6d8897251888&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=1520&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
    `}</div>{`
    `}<pre parentName="div">{`      `}<code parentName="pre">{`LGNAccordion("Title",leadingIcon: Image(systemName: "xmark"), isInitialyExpanded: true)
        {Text("Put your expandable content here. It can be anything.")}`}</code>{`
    `}</pre>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Expand OFF`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F4197e36b-e739-4ddd-b83e-9eb55d71f2f6%2FScreen_Shot_2022-12-15_at_2.31.26_PM.png?id=3c65064b-d135-4e49-ba6c-0251fb13b893&table=block&spaceId=35a14a17-7ee4-461e-a539-293d39e34c63&width=1520&userId=0a64858e-7521-4427-8950-d546112789a2&cache=v2"
          }}></img>{`
    `}</div>{`
    `}<pre parentName="div">{`      `}<code parentName="pre">{`LGNAccordion("Title", leadingIcon: Image(systemName: "xmark"))
        {Text("Put your expandable content here. It can be anything.")}`}</code>{`
    `}</pre>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the main text string you want to display on accordion header`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leadingIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the Image() you want to display on the left hand side of your header text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hasBottomLine`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the boolean to decide whether or not the accordion has bottom line`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`isInitialyExpanded`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`whether or not the accordion is initially displayed as expanded or collapsed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />
    <h2>{`Example Project`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAccordion(
  "title",
  leadingIcon: Image(named: "foo"),
  hasBottomLine: true,
  isInitialyExpanded: false
) {
  Text("hello")
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      